import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M6310 8739 c-321 -21 -640 -208 -827 -484 -124 -184 -187 -393 -187
-620 1 -232 62 -435 190 -626 56 -83 216 -247 250 -255 60 -15 114 26 114 87
0 37 -6 46 -75 111 -135 128 -233 289 -277 458 -33 129 -31 330 5 462 76 280
275 510 533 618 140 58 215 73 364 73 149 0 226 -16 364 -73 258 -108 463
-344 537 -620 33 -121 33 -346 0 -470 -44 -165 -135 -314 -275 -448 -67 -64
-76 -77 -76 -108 0 -46 45 -94 88 -94 36 0 71 26 166 124 121 124 207 276 259
456 161 556 -143 1151 -686 1345 -162 58 -293 76 -467 64z"/>
<path d="M6328 8369 c-134 -11 -281 -71 -386 -157 -157 -127 -238 -271 -272
-481 -32 -197 49 -454 188 -599 41 -42 46 -44 87 -40 50 6 73 24 81 66 7 34
-5 59 -56 122 -91 110 -124 204 -125 350 0 116 12 173 58 265 41 84 161 201
249 243 259 126 560 42 721 -200 128 -194 112 -483 -37 -653 -57 -67 -69 -91
-62 -129 7 -38 49 -66 97 -66 40 0 87 46 154 155 63 101 93 186 107 305 30
257 -69 496 -276 665 -142 115 -329 170 -528 154z"/>
<path d="M6325 7941 c-118 -31 -217 -140 -240 -264 -11 -58 13 -150 56 -218
21 -32 43 -59 48 -59 19 0 20 -31 2 -87 -10 -32 -33 -105 -50 -163 -18 -58
-39 -125 -47 -150 -8 -25 -46 -151 -84 -280 -82 -275 -231 -767 -260 -860 -30
-97 -27 -126 16 -166 50 -48 87 -46 157 9 46 35 223 181 422 346 27 22 53 41
57 41 4 0 27 -17 51 -37 24 -21 53 -45 63 -53 35 -27 319 -262 344 -284 80
-69 124 -74 176 -22 43 43 44 80 5 201 -15 50 -50 162 -76 250 -26 88 -81 270
-122 405 -72 236 -87 283 -117 388 -18 63 -124 406 -131 423 -3 8 10 32 30 53
91 100 116 235 63 348 -31 67 -91 128 -155 158 -50 23 -158 34 -208 21z m100
-758 c8 -32 40 -137 69 -233 30 -96 64 -209 76 -250 11 -41 25 -85 31 -98 5
-13 9 -29 9 -36 0 -7 -37 -44 -82 -82 -46 -38 -92 -77 -102 -86 -25 -23 -26
-23 -61 10 -16 15 -63 53 -102 86 -40 32 -73 60 -73 63 0 2 34 118 76 256 42
139 88 291 102 340 13 48 28 87 33 87 4 0 15 -26 24 -57z m-247 -900 c23 -20
42 -41 42 -47 -1 -11 -139 -127 -161 -135 -7 -2 -10 2 -7 10 3 8 18 58 34 111
16 54 34 98 39 98 6 0 30 -17 53 -37z m524 -19 c33 -98 49 -156 44 -161 -5 -6
-80 52 -136 105 l-35 33 34 27 c19 15 39 32 45 39 18 20 30 9 48 -43z"/>
<path d="M10047 5020 c-59 -15 -95 -38 -112 -70 -23 -45 -19 -76 18 -113 38
-38 83 -53 182 -63 100 -10 123 -24 89 -58 -18 -19 -118 -22 -178 -5 -28 8
-41 18 -49 40 -10 25 -17 29 -50 29 l-38 0 3 -72 3 -73 60 -1 c33 -1 88 -5
122 -9 79 -10 177 8 220 41 40 30 51 76 29 128 -20 48 -72 71 -194 87 -97 12
-124 27 -102 54 15 18 115 20 173 4 29 -8 41 -18 49 -40 8 -24 15 -29 44 -29
l34 0 0 75 0 75 -34 0 c-21 0 -36 -6 -39 -15 -5 -13 -10 -13 -42 0 -46 20
-140 27 -188 15z"/>
<path d="M8182 5010 c-78 -12 -158 -55 -189 -101 -22 -33 -25 -47 -21 -102 7
-109 66 -159 214 -180 140 -19 261 15 305 87 29 48 25 66 -14 66 -24 0 -44
-10 -74 -39 -104 -96 -293 -48 -293 74 0 44 28 90 66 108 86 41 229 6 249 -60
8 -30 13 -33 47 -33 l39 0 -3 88 -3 87 -30 3 c-19 3 -33 -2 -42 -13 -11 -16
-15 -16 -50 -2 -51 20 -135 27 -201 17z"/>
<path d="M8754 5011 c-149 -24 -224 -88 -224 -192 0 -124 78 -183 259 -195
102 -7 217 14 268 48 50 34 77 97 70 163 -6 59 -25 91 -74 125 -64 44 -197 67
-299 51z m159 -85 c43 -18 67 -46 74 -90 12 -77 -39 -134 -132 -143 -103 -11
-185 43 -185 120 0 44 27 92 63 110 40 20 137 22 180 3z"/>
<path d="M2467 5003 c-4 -3 -7 -35 -7 -70 l0 -63 40 0 c39 0 40 1 40 35 l0 35
65 0 66 0 -3 -117 -3 -118 -37 -3 c-36 -3 -38 -5 -38 -38 l0 -34 140 0 140 0
0 34 c0 33 -2 35 -37 38 l-38 3 -3 118 -3 117 66 0 65 0 0 -35 c0 -34 1 -35
40 -35 l41 0 -3 68 -3 67 -261 3 c-143 1 -263 -1 -267 -5z"/>
<path d="M3040 4976 c0 -35 1 -36 35 -36 l36 0 -3 -117 -3 -118 -26 -3 c-22
-3 -28 -9 -31 -38 l-4 -34 129 0 128 0 -3 33 c-3 28 -7 32 -35 35 -32 3 -33 5
-33 48 0 42 1 44 31 44 47 0 67 -16 74 -58 12 -74 16 -82 45 -97 58 -30 168
-12 184 30 11 29 7 43 -14 48 -12 3 -20 0 -20 -8 0 -7 -7 -18 -15 -25 -20 -17
-45 4 -45 37 0 15 -12 36 -30 53 l-30 28 29 12 c49 20 73 54 73 99 -1 50 -5
57 -45 78 -27 15 -66 18 -229 21 l-198 4 0 -36z m340 -51 c7 -8 10 -25 6 -39
-6 -26 -37 -36 -112 -36 l-44 0 0 45 0 45 69 0 c48 0 72 -4 81 -15z"/>
<path d="M3599 5005 c-1 -3 -1 -14 -1 -25 0 -37 2 -40 32 -40 l31 0 -3 -117
c-3 -117 -3 -118 -28 -121 -35 -5 -44 -16 -36 -46 l6 -26 233 2 232 3 0 70 0
70 -35 0 c-32 0 -35 -3 -40 -35 l-5 -35 -102 -3 -103 -3 0 46 0 45 70 0 70 0
0 35 0 35 -70 0 -70 0 0 40 0 40 105 0 105 0 0 -35 c0 -34 1 -35 40 -35 l41 0
-3 68 -3 67 -232 3 c-128 1 -233 0 -234 -3z"/>
<path d="M4108 5005 c-2 -3 -3 -18 0 -35 4 -25 9 -30 33 -30 l30 0 -3 -117 -3
-118 -27 -3 c-25 -3 -28 -8 -28 -38 l0 -34 111 0 110 0 -3 32 c-3 28 -8 34
-33 38 l-30 5 -3 78 c-2 66 -1 77 11 70 8 -4 68 -56 133 -115 106 -98 121
-108 156 -108 l38 0 0 155 0 155 30 0 c28 0 30 3 30 35 l0 35 -111 0 -110 0 3
-32 c3 -29 7 -33 36 -36 l32 -3 0 -60 c0 -41 -4 -59 -12 -59 -7 1 -58 43 -113
95 l-100 95 -86 0 c-47 0 -88 -2 -91 -5z"/>
<path d="M4710 4976 c0 -33 2 -36 30 -36 l31 0 -3 -117 -3 -118 -27 -3 c-25
-3 -28 -8 -28 -39 l0 -35 188 4 c169 3 191 6 227 26 56 30 70 42 88 79 18 35
22 118 8 155 -15 38 -45 67 -97 90 -44 20 -69 23 -231 26 l-183 4 0 -36z m361
-65 c26 -26 29 -36 29 -91 0 -51 -4 -65 -24 -85 -25 -25 -86 -39 -146 -33
l-35 3 -3 118 -3 117 76 0 c71 0 79 -2 106 -29z"/>
<path d="M5270 4975 c0 -31 3 -35 25 -35 19 0 49 -23 110 -84 75 -75 85 -89
85 -120 0 -33 -2 -36 -30 -36 -28 0 -30 -3 -30 -35 l0 -35 125 0 125 0 0 34
c0 32 -3 35 -32 38 -29 3 -33 7 -36 35 -3 27 5 41 41 80 24 27 59 65 77 86 27
31 40 37 72 37 37 0 38 1 38 35 l0 35 -110 0 -110 0 0 -35 c0 -25 4 -35 15
-35 11 0 4 -14 -22 -45 -20 -25 -42 -45 -47 -45 -6 0 -27 16 -48 37 -35 34
-37 39 -31 80 l6 43 -112 0 -111 0 0 -35z"/>
<path d="M5870 4939 l0 -70 38 3 c34 3 37 6 40 36 l3 32 60 0 59 0 0 -119 0
-120 -37 -3 c-35 -3 -38 -6 -38 -33 l0 -30 138 -3 137 -3 0 35 c0 33 -2 35
-37 38 l-38 3 -3 118 -3 117 66 0 65 0 0 -35 c0 -34 1 -35 40 -35 l40 0 0 70
0 70 -265 0 -265 0 0 -71z"/>
<path d="M6450 4975 c0 -32 2 -35 30 -35 l31 0 -3 -117 -3 -118 -27 -3 c-25
-3 -28 -8 -28 -38 l0 -34 235 0 236 0 -3 73 -3 72 -35 0 c-32 0 -35 -3 -40
-35 l-5 -35 -100 0 -100 0 -3 43 -3 42 70 0 71 0 0 35 0 35 -70 0 -70 0 0 40
0 40 105 0 105 0 0 -35 c0 -34 1 -35 40 -35 l40 0 0 70 0 70 -235 0 -235 0 0
-35z"/>
<path d="M6960 4975 c0 -32 2 -35 30 -35 l30 0 0 -120 0 -120 -30 0 c-28 0
-30 -3 -30 -35 l0 -35 235 0 235 0 0 69 c0 39 -3 72 -7 74 -5 3 -23 2 -40 0
-30 -5 -33 -9 -33 -39 l0 -35 -102 3 -103 3 -3 118 -3 117 36 0 c33 0 35 2 35
35 l0 35 -125 0 -125 0 0 -35z"/>
<path d="M7470 4975 c0 -32 2 -35 30 -35 l30 0 0 -120 0 -120 -30 0 c-28 0
-30 -3 -30 -35 l0 -35 235 0 236 0 -3 73 -3 72 -35 0 c-33 0 -35 -2 -38 -38
l-3 -38 -102 3 -102 3 -3 43 -3 42 70 0 71 0 0 35 0 35 -70 0 -70 0 0 40 0 40
105 0 105 0 0 -35 c0 -34 1 -35 40 -35 l40 0 0 70 0 70 -235 0 -235 0 0 -35z"/>
<path d="M9160 4975 c0 -32 2 -35 30 -35 l30 0 0 -120 0 -120 -30 0 c-28 0
-30 -3 -30 -35 l0 -35 120 0 120 0 0 34 c0 34 -1 35 -42 38 -43 3 -43 3 -46
45 -6 86 8 83 89 -13 72 -87 88 -104 101 -104 3 0 41 43 83 95 43 52 82 95 87
95 5 0 8 -26 6 -57 -3 -57 -3 -58 -35 -61 -30 -3 -33 -6 -33 -38 l0 -34 125 0
125 0 0 35 c0 32 -2 35 -30 35 l-30 0 0 120 0 120 30 0 c27 0 30 3 30 30 l0
30 -89 0 -89 0 -73 -87 c-82 -99 -95 -113 -103 -113 -2 0 -44 47 -92 105 l-87
105 -83 0 -84 0 0 -35z"/>
<path d="M9252 4440 c-18 -11 -41 -34 -52 -52 -39 -65 -18 -183 39 -212 93
-48 233 -19 243 49 2 16 -3 21 -30 23 -21 2 -41 -3 -51 -12 -58 -53 -131 -14
-131 69 0 35 6 52 25 70 30 31 50 31 100 0 63 -38 94 -30 81 21 -14 56 -158
84 -224 44z"/>
<path d="M9570 4438 c-89 -60 -93 -199 -7 -262 22 -16 43 -21 96 -21 107 0
155 44 156 144 0 103 -43 150 -146 158 -56 4 -69 2 -99 -19z m134 -57 c39 -44
27 -155 -19 -167 -67 -17 -98 18 -92 105 3 38 9 58 22 67 27 20 69 17 89 -5z"/>
<path d="M9991 4444 c-17 -12 -26 -13 -34 -5 -6 6 -28 11 -49 11 l-38 0 0
-146 0 -145 43 3 42 3 5 103 c4 88 8 106 24 118 23 16 35 17 56 4 11 -7 16
-36 20 -117 l5 -108 43 -3 42 -3 0 100 c0 96 1 100 26 120 31 25 35 25 59 7
15 -11 19 -31 23 -120 l5 -106 38 0 39 0 0 119 c0 111 -1 120 -24 144 -13 14
-36 29 -51 33 -32 8 -97 -10 -115 -31 -11 -13 -16 -12 -42 10 -34 29 -83 33
-117 9z"/>
<path d="M9058 4243 c-23 -6 -32 -37 -18 -63 9 -17 19 -20 48 -18 35 3 37 5
40 39 3 31 -1 37 -20 43 -13 3 -25 5 -28 5 -3 -1 -13 -3 -22 -6z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
